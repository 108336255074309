<template lang="pug">
.om-tactic-recommendation-modal-components
  template-preview
  new-campaign
  last-request-date-old
</template>

<script>
  export default {
    components: {
      TemplatePreview: () => import('@/components/Modals/TemplatePreview'),
      NewCampaign: () => import('@/components/Modals/NewCampaign.vue'),
      LastRequestDateOld: () => import('@/components/Modals/LastRequestDateOld.vue'),
    },
  };
</script>
